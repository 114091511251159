import styled from "@emotion/styled";

const Padding = styled.div`
  padding-top: 50px;
  padding-bottom: 170px;
`;

const CoverImage = styled.img`
  width: 98.5%;
  height: auto;
  max-height: 466px;
  object-fit: cover;
  float: right;
  z-index: 40;
`;

const CoverRect = styled.div`
  left: 0;
  top: -15px;
  z-index: -1;
  width: 68px;
  height: 25vh;
  background-color: #f38f1d;
  position: absolute;
`;

const BlogPostTitle = styled.h1`
  font-family: Roboto;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  margin-top: 46px;
  margin-bottom: 26px;
`;

const BlogPostSubtitle = styled.h2`
  font-family: Roboto;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 75px;
`;

const BlogPostDateDivider = styled.hr`
  width: 100%;
  max-width: 240px;
  height: 1px;
  background-color: #979797;
  margin: 0;
`;

const BlogPostDate = styled.h6`
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.42px;
  color: #a7a9ac;
  margin-top: 3px;
  text-transform: uppercase;
`;

const BlogPostTags = styled.ul`
  padding: 0;
  margin: 0 0 57px 0;
  list-style-type: none;
  li {
    display: inline;
    margin-right: 12px;
  }
  li:last-child {
    margin-right: 0;
  }
`;

const BlogPostTag = styled.span`
  font-family: Roboto;
  font-size: 17px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;

const BlogPostContent = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
`;

const BlogPostHighlightContent = styled.h3`
  font-family: Roboto;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
`;

const BlogPostHighlightImage = styled.img`
  width: 96%;
  height: auto;
  object-fit: contain;
  float: left;
`;

const HighlightRect = styled.div`
  right: 0;
  top: -20px;
  width: 435px;
  height: 166px;
  background-color: #f38f1d;
  position: absolute;
  z-index: -1;
`;
const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px;
`;
const styles = {
  Padding,
  CoverImage,
  CoverRect,
  BlogPostTitle,
  BlogPostSubtitle,
  BlogPostDate,
  BlogPostDateDivider,
  BlogPostTags,
  BlogPostTag,
  BlogPostContent,
  BlogPostHighlightContent,
  BlogPostHighlightImage,
  HighlightRect,
  Container
};

export default styles;
