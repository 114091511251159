import { Visible, Row, Col } from "react-grid-system";
import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";

import { graphql } from "gatsby";
import { useIntl } from "react-intl";

import { Layout, SocialNav } from "../../components";
import Blog from "../../components/Blog";

import Preview from "../../components/common/Preview";

import styles from "./styles";

interface PostTemplateProps extends RouteComponentProps {
  data: {
    prismicPost: {
      id: string;
      tags: string[];
      data: {
        date: string;
        intro: string;
        title: string;
        content: { html: string };
        cover: { alt: string; url: string };
        highlight_paragraph: { html: string };
        highlight_image: { alt: string; url: string };
        seo_title?: string;
        seo_description?: string;
        seo_keywords?: string;
        seo_image?: { alt: string; url: string };
        canonical_url?: {
          url: string;
        };
      };
    };
    allPrismicPost: {
      nodes: Array<{
        uid: string;
        data: {
          date: string;
          intro: string;
          title: string;
          cover: { alt: string; url: string };
        };
      }>;
    };
  };
}

const PostTemplate: FunctionComponent<PostTemplateProps> = ({ data }) => {
  const { formatMessage } = useIntl();

  const {
    Padding,
    CoverImage,
    CoverRect,
    BlogPostTitle,
    BlogPostSubtitle,
    BlogPostDate,
    BlogPostDateDivider,
    BlogPostTags,
    BlogPostTag,
    BlogPostContent,
    BlogPostHighlightContent,
    BlogPostHighlightImage,
    HighlightRect,
    Container
  } = styles;

  const formatDate = () =>
    new Date(data.prismicPost.data.date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric"
    });

  const [htmlContent, setContent] = useState("");
  const [htmlHighlight, setHighlight] = useState("");

  useEffect(() => {
    if (data.prismicPost.data) {
      setContent(data.prismicPost.data.content.html);
      setHighlight(data.prismicPost.data.highlight_paragraph.html);
    }
  }, [data]);

  return (
    
    <Layout
      metaTitle={data.prismicPost.data.seo_title || 'Decunify'}
      metaDescription={
        data.prismicPost.data.seo_description ||
        formatMessage({
          id: "pages.blog-post.meta-description"
        })
      }
      metaKeywords={
        data.prismicPost.data.seo_keywords ||
        formatMessage({
          id: "pages.blog-post.meta-keywords"
        })
      }
      metaImage={data.prismicPost.data.seo_image || 'Decunify'}
      metaURL={data.prismicPost.data.canonical_url?.url || 'Decunify'}
      metaType={"article"}
    >
      <Padding>
        <Container>
          <Row>
            <Col xs={12}>
              <div style={{ position: "relative" }}>
                <CoverRect />
                <CoverImage
                  alt={data.prismicPost.data.cover.alt}
                  src={data.prismicPost.data.cover.url}
                />
              </div>
            </Col>
            <Col xs={12}>
              <BlogPostTitle>{data.prismicPost.data.title}</BlogPostTitle>
            </Col>
            <Col xs={12} lg={6}>
              <BlogPostSubtitle>{data.prismicPost.data.intro}</BlogPostSubtitle>
            </Col>
            <Col xs={12}>
              <BlogPostDateDivider />
              <BlogPostDate>{formatDate()}</BlogPostDate>
            </Col>
            <Col xs={12}>
              <BlogPostTags>
                {data.prismicPost.tags.map((tag, index) => (
                  <li key={`tag#${index}`}>
                    <BlogPostTag>{`#${tag}`}</BlogPostTag>
                  </li>
                ))}
              </BlogPostTags>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <BlogPostContent
                style={{ marginBottom: "70px" }}
                dangerouslySetInnerHTML={{
                  __html: htmlContent
                }}
              />
            </Col>
            {data.prismicPost.data?.highlight_paragraph?.html && (
              <Col xs={12}>
                <Row align="center">
                  <Col xs={12} lg={6}>
                    <BlogPostHighlightContent
                      dangerouslySetInnerHTML={{
                        __html: htmlHighlight
                      }}
                    />
                  </Col>
                  {data.prismicPost.data?.highlight_image?.url && (
                    <Visible lg xl>
                      <Col lg={6}>
                        <div style={{ position: "relative" }}>
                          <HighlightRect />
                          <BlogPostHighlightImage
                            alt={data.prismicPost.data.highlight_image.alt}
                            src={data.prismicPost.data.highlight_image.url}
                          />
                        </div>
                      </Col>
                    </Visible>
                  )}
                </Row>
              </Col>
            )}
          </Row>
          <Row style={{ marginTop: "90px" }}>
            <Col xs={12}>
              <SocialNav />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Blog posts={data.allPrismicPost} seeNext />
            </Col>
          </Row>
        </Container>
      </Padding>
    </Layout>
  );
};

export const query = graphql`
  # query($uid: String!, $lang: String!) {
  #   post: prismicPost(uid: { eq: $uid }, lang: { eq: $lang }) {
  query($uid: String!) {
    prismicPost(uid: { eq: $uid }, lang: { eq: "pt-pt" }) {
      id
      tags
      data {
        preview_image {
          alt
          url
        }
        cover {
          alt
          url
        }
        date
        intro
        title
        content {
          html
        }
        highlight_paragraph {
          html
        }
        highlight_image {
          url
          alt
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          alt
          url
        }
        canonical_url {
          url
        }
      }
    }
    # posts: allPrismicPost(filter: { lang: { eq: $lang } }) {
    allPrismicPost(
      filter: { lang: { eq: "pt-pt" } }
      limit: 3
      sort: { order: DESC, fields: first_publication_date }
    ) {
      nodes {
        uid
        data {
          preview_image {
            alt
            url
          }
          date
          intro
          title
        }
      }
    }
  }
`;

export default Preview(PostTemplate);
